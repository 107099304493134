<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>Security</h2>
      </v-col>
    </v-row>

    <v-divider class="mt-3 mb-5"></v-divider>

    <v-card flat>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="opds"
        :loading="loading"
        :search="search"
        item-key="name"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.nomor`]="{ item }">
          {{
            opds
              .map(function (x) {
                return x.nama;
              })
              .indexOf(item.nama) + 1
          }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon small left @click="viewItem(item)"> mdi-archive </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>


<script>
import Cookie from "@/helper/cookie.js";

export default {
  data: () => ({
    token: Cookie.get("token"),
    loading: true,
    session: "",
    search: "",

    opds: [],

    headers: [
      {
        text: "Nomor",
        value: "nomor",
        width: "50px",
        align: "center",
        sortable: false,
      },
      { text: "Nama OPD", align: "start", value: "nama" },
      { text: "Action", value: "action", width: "100px" },
    ],
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;
      const url = process.env.VUE_APP_AUTH + "security/opd";
      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.opds = response.data.mapData;
          this.loading = false;
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    viewItem(item) {
      this.$router
        .push({ path: "/security/" + item.id, query: { unor: item.nama } })
        .catch(() => {});
    },
  },
};
</script>
